import React, { useState } from 'react';
import { ReactComponent as DayIcon } from './theme/day.svg';
import { ReactComponent as NightIcon } from './theme/night.svg';

declare global {
  interface Window {
    __theme: 'dark' | 'light';
    __onThemeChange: (newTheme: 'dark' | 'light') => void;
    __setPreferredTheme: (newTheme: 'dark' | 'light') => void;
  }
}

const Theme: React.FC = () => {
  const [, setTheme] = useState(); // just force rerender, nothing useful here

  window.__onThemeChange = theme => {
    setTheme(theme);
  };

  const changeTheme = () => {
    window.__setPreferredTheme(window.__theme === 'dark' ? 'light' : 'dark');
    setTheme(window.__theme);
  };

  if (window.__theme === 'light') {
    return <NightIcon onClick={changeTheme} width="32" />;
  }

  return <DayIcon onClick={changeTheme} width="32" />;
};

export default Theme;
