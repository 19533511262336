import React from 'react';

import Title from './Title';
import Product from './Product';
import Theme from './Theme';
import ContactForm from './ContactForm';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <section>
        <Title title="Klouby bolej?">
          <Theme />
        </Title>
        <p>
          Klouby bolej – stránka, kde se dozvíte základní informace o českých produktech na podporu pohybového aparátu.
        </p>
        <p>
          A také se dozvíte o možnosti, jak nakupovat tyto produkty za velkoobchodní ceny. Dále můžete dostávat
          věrnostní bonusy a díky nim si zlevníte další nákupy.
        </p>
        <p>
          Za vývojem těchto produktů stojí Ing. Zbyněk Prokšan, což je člověk, který pracoval ve výzkumném ústavu
          potravinářském a s výrobou produktů pro zdraví má víc než 30 leté zkušenosti
        </p>
      </section>
      <section>
        <Title title="Jak se stát členem" />
        <p>Jak se stát členem klubu a získat možnost nákupu produktů za velkoobchodní ceny?</p>
        <p>
          Každý zájemce o produkty pro zdraví z Klubu Global 3000 je může nakupovat <b>za velkoobchodní ceny</b>. K tomu
          je potřeba odebrat startovací balíček se vzorky, katalogem, marketingovými materiály a vyplnit základní údaje
          o sobě do registračního formuláře. Registrací nevzniká povinnost nákupů. Vše je dobrovolné a každý člen může
          nakupovat pouze, když chce. Členství platí doživotně.
        </p>
        <p>
          Další výhody plynoucí z členství: Za nákupy produktů Klubu Global 3000 (a případné nákupy u partnerů klubu)
          sbíráte body, za body získáte odměnu dle marketingového plánu. Odměnu můžete vyčerpat třeba při dalším nákupu
          jako slevu.
        </p>
        <p>
          Celý sortiment Klubu Global 3000 najdete v katalogu:
          <a href="https://www.global3000.cz/download/katalog_global.pdf" target="_blank" rel="noopener noreferrer">
            ZDE
          </a>
        </p>
      </section>
      <section>
        <Title title="Produkty" />
        <Product
          image="/products/prokloub_visen"
          title="PROKLOUB (ovoce nebo višeň) "
          description="je podpůrný a ochranný přípravek se štěpeným kolagenem, glukosaminem, chondroitinem a šestnácti vitamíny a minerály."
        >
          <p>
            Suroviny ve výrobku obsažené přispívají k regeneraci kloubního kolagenu a zpomalení artrotických procesů.
          </p>
          <p>
            ProKloub obsahuje hluboce štěpený kolagen, který snadno prochází stěnou zažívacího traktu do krevního oběhu.
            Kolagen je obsažen v kloubní chrupavce. Včasná podpora hybnosti kloubu je vhodná pro bezbolestný pohyb i ve
            vysokém věku.
          </p>
          <p>
            Na obnovu hybnosti mají pozitivní vliv zejména peptidy a polypeptidy kolagenu spolu se stopovými prvky. To
            neplatí pouze pro vytváření a obnovu kolagenových substancí v kloubech, ale zároveň se zapojením těchto
            látek do metabolizmu výrazně zlepšuje kvalita vlasů, nehtů a pokožky.
          </p>
          <p>
            (Pomáhá na všechny klouby v těle. Pokud budete užívat 1 lžičku ráno (dopoledne) a jednu lžičku večer a
            budete dodržovat pitný režim (!), tak zhruba po 3 týdnech zjistíte, že to funguje. Mám s ním bohaté
            zkušenosti a hodně spokojených uživatelů. Já mám radši variantu višeň, která mi víc chutná a jelikož jde o
            větší balení, tak i déle vydrží).
          </p>
          <p>Běžná cena: 650 Kč (dávka na 1,5 - 3 měsíce), členové klubu nakupují za velkoobchodní ceny</p>
          <p>Obsah potravinových doplňků v denní dávce (11g):</p>
          <ul>
            <li>Vitamín C - 168,0 mg (210 % doporučené denní dávky)</li>
            <li>Niacin (NE) - 7,20 mg (45 % doporučené denní dávky)</li>
            <li>Vitamín E (α-TE) - 4,00 mg (33 % doporučené denní dávky)</li>
            <li>Kyselina pantotenová - 2,40 mg (40 % doporučené denní dávky)</li>
            <li>Vitamín B6 - 0,80 mg (57 % doporučené denní dávky)</li>
            <li>Vitamín B2 - 0,64 mg (45 % doporučené denní dávky)</li>
            <li>Vitamín B1 - 0,55 mg (51 % doporučené denní dávky)</li>
            <li>Kyselina listová - 80,14 μg (40 % doporučené denní dávky)</li>
            <li>Biotin - 60,10 μg (120 % doporučené denní dávky)</li>
            <li>Vitamín D - 2,00 μg (40 % doporučené denní dávky)</li>
            <li>Vitamín B12 - 0,40 μg (16 % doporučené denní dávky)</li>
            <li>Vápník - 95,0 mg (12 % doporučené denní dávky)</li>
            <li>Hořčík - 70,0 mg (18 % doporučené denní dávky)</li>
            <li>Křemík - 18,0 mg</li>
            <li>Fosfor - 9,3 mg (1 % doporučené denní dávky)</li>
            <li>Bor - 1,87 mg</li>
            <li>Hydrolyzovaný kolagen - 7560,0 mg</li>
            <li>Glukosamin sulfát - 199,0 mg</li>
            <li>Chondroitin sulfát - 40,0 mg</li>
            <li>L-Lyzin 5,6 mg</li>
          </ul>
        </Product>
        <Product
          image="/products/prokloub_maxi"
          title="Prokloub Maxi"
          description="je podpůrný a ochranný přípravek se štěpeným kolagenem, glukosaminem, chondroitinem, šestnácti vitamíny, minerály, MSM a kyselinou hyaluronovou."
        >
          <p>
            PROKLOUB MAXI - je vylepšená varianta PROKLOUBu. Má sice méně kolagenu, ale je obohacen o kyselinu
            hyaluronovou a MSM, což je protizánětlivá látka. PROKLOUB MAXI doporučuji hlavně lidem, kteří mají velké
            problémy s klouby a také bolesti. Někteří uživatelé sdělují zkušenosti už po týdnu užívání! Opět doporučím
            dodržovat pitný režim!!!
          </p>
          <p>Obsah potravinových doplňků v denní dávce (11g):</p>
          <ul>
            <li>Vitamín C - 168,30 mg (210 % doporučené denní dávky)</li>
            <li>Niacin (NE) - 7,20 mg (45 % doporučené denní dávky)</li>
            <li>Vitamín E (α-TE) - 4,00 mg (33 % doporučené denní dávky)</li>
            <li>Kyselina pantotenová - 2,40 mg (40 % doporučené denní dávky)</li>
            <li>Vitamín B6 - 0,80 mg (57 % doporučené denní dávky)</li>
            <li>Vitamín B2 - 0,64 mg (45 % doporučené denní dávky)</li>
            <li>Vitamín B1 - 0,55 mg (51 % doporučené denní dávky)</li>
            <li>Kyselina listová - 80,14 μg (40 % doporučené denní dávky)</li>
            <li>Biotin - 60,30 μg (120 % doporučené denní dávky)</li>
            <li>Vitamín D - 2,00 μg (40 % doporučené denní dávky)</li>
            <li>Vitamín B12 - 0,40 μg (16 % doporučené denní dávky)</li>
            <li>Vápník - 100,0 mg (12 % doporučené denní dávky)</li>
            <li>Hořčík - 61,0 mg (16 % doporučené denní dávky)</li>
            <li>Křemík - 20,0 mg</li>
            <li>Fosfor - 9,8 mg (1,4 % doporučené denní dávky)</li>
            <li>Bor - 1,9 mg</li>
            <li>Hydrolyzovaný kolagen - 2750,0 mg</li>
            <li>Glukosamin sulfát - 199,0 mg</li>
            <li>Chondroitin sulfát - 40,0 mg</li>
            <li>L-Lyzin - 5,60 mg</li>
            <li>MSM (Methylsulfonylmethan) - 385,00 mg</li>
            <li>Kyselina hyaluronová - 11,00 mg</li>
          </ul>
        </Product>
        <Product
          image="/products/prokloub_mast"
          title="Prokloub mast"
          description="je revoluční mast s chondroitinem, glukosaminem a kyselinou hyaluronovou. Posiluje hydrataci kloubní chrupavky a doplňuje důležité látky do kloubní tekutiny."
        >
          <p>
            Mast s velice rychlou vstřebatelností je přínosná pro odstranění nebo zmírnění artrotických projevů v
            kloubní chrupavce. Pomáhá regeneraci poškozené kloubní chrupavky, hydratuje kloubní chrupavku a tím pomáhá v
            její výživě.
          </p>
          <p>
            Maže se na klouby pod kůží, například koleno, loket, kotník apod. Navlhčenými prsty nanesete malé množství
            masti, která se rychle vstřebá, pokud zůstane na kůži trochu nevstřebané masti, tak opět navlhčete prsty a
            znovu rozetřete. Účinkuje velice rychle, ale pouze na zmíněné klouby.
          </p>
        </Product>
        <Product
          image="/products/florien_3000"
          title="Florien 3000"
          description="Gel s rostlinnými extrakty vhodný k péči o klouby, svaly a cévy. Gel se používá k potírání nebo masáži jako podpůrný prostředek k regeneraci kloubů, svalů a cév."
        >
          <p>Obsahuje kaštanový, kostivalový a jalovcový extrakt, dále obsahuje kafr a eukalyptovou silici.</p>
          <p>Dermatologicky testováno.</p>
        </Product>
        <Product
          image="/products/florinea"
          title="Florinea"
          description="Přírodní chladivý gel, který se vyznačuje příjemnou eukalypto-mentolovou vůní."
        >
          <p>
            Používá se k masáži pokožky v oblasti svalů a kloubů. Obsahuje kaštanový a jalovcový extrakt, borovicovou,
            eukalyptovou a peprnomátovou silici, které jsou vhodné pro péči o pokožku při svalové únavě.
          </p>
          <p>
            Přispívá ke snížení bolestivosti kloubů a uvolnění napětí svalů. Je vhodný po fyzické námaze či sportu. Má
            kladný vliv na revmatické onemocnění. Obsažené silice také poskytují úlevu při nachlazeních a různých
            chřipkových onemocněních.
          </p>
        </Product>
      </section>
      <section>
        <Title title="Kontakt" />
        <div className="contact-address">
          <address>
            <p>Libor Klíma</p>
            <p>
              Telefon:
              <a href="tel:+420608684011">+420 608 684 011</a>
            </p>
            <p>
              E-mail:
              <a href="mailto:libor@seznam.us">libor@seznam.us</a>
            </p>
            <p>
              Facebook:
              <a href="https://www.facebook.com/klouby" target="_blank" rel="noopener noreferrer">
                Klouby bolej? Rychle volej!
              </a>
            </p>
          </address>
          <img src="/photos/libor-klima.jpg" alt="Libor Klíma" />
        </div>
        <ContactForm />
      </section>
    </React.Fragment>
  );
};

export default App;
