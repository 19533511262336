import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldMetaProps } from 'formik';
import * as yup from 'yup';

import { MailRequest } from '../api/send-mail';

const validationMessages = {
  email: 'Toto není platný email',
  phone: 'Pole obsahuje neplatné znaky. Povolené jsou pouze čísla, "+" a mezery',
  maxLength: (maxLength: number) => `Maximální délka pole je ${maxLength} znaků`,
  required: 'Musíte zadat alespoň jeden kontaktní údaj',
};

const validationSchema = yup.object().shape(
  {
    email: yup
      .string()
      .email(validationMessages.email)
      .max(50, validationMessages.maxLength(50))
      .when('phone', { is: phone => !phone, then: yup.string().required(validationMessages.required) }),
    phone: yup
      .string()
      .matches(/^[0-9\s+]+$/, validationMessages.phone)
      .max(25, validationMessages.maxLength(25))
      .when('email', { is: email => !email, then: yup.string().required(validationMessages.required) }),
    note: yup.string().max(500, validationMessages.maxLength(500)),
  },
  [['email', 'phone']],
);

function parseResponse(response: Response) {
  if (response.status !== 201) {
    throw new Error('Sending mail failed');
  }

  return response.json();
}

const ContactForm = () => {
  const formElementRef = useRef<HTMLFormElement>(null);
  const [showGlobalError, setShowGlobalError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const invalidateField = (fieldMeta: FieldMetaProps<string>) => {
    return fieldMeta.error && fieldMeta.touched ? 'contact-form__invalid' : '';
  };

  const loader = (
    <React.Fragment>
      <div className="loader">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
      <span>Odesílání</span>
    </React.Fragment>
  );

  return (
    <Formik<MailRequest>
      initialValues={{ email: '', phone: '', note: '' }}
      validationSchema={validationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (formElementRef.current) {
          formElementRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        setShowGlobalError(false);
        setShowSuccessMessage(false);
        formikHelpers.setSubmitting(true);

        fetch('/api/sendmail', {
          headers: { 'Accept': 'application/json; charset=utf-8', 'Content-Type': 'application/json; charset=utf-8' },
          method: 'POST',
          body: JSON.stringify(formValues),
        })
          .then(parseResponse)
          .then(() => {
            formikHelpers.resetForm();
            setShowSuccessMessage(true);
          })
          .catch(() => setShowGlobalError(true))
          .finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {formikBag => (
        <Form className="contact-form" ref={formElementRef} noValidate>
          <p>
            Chcete, abychom vás kontaktovali a sdělili další informace o našich produktech, nebo o možnosti jejich
            nákupu za klubové ceny? Vyplňte email nebo telefon.
          </p>
          {showGlobalError && <p className="contact-form__error">Došlo k chybě. Zkuste to prosím později.</p>}
          {showSuccessMessage && (
            <p className="contact-form__success">ODESLÁNO! V nejbližší době vás budeme kontaktovat.</p>
          )}
          <label className={invalidateField(formikBag.getFieldMeta('email'))}>
            Váš email:
            <Field name="email" type="email" placeholder="mujmail@domena.cz" />
            <ErrorMessage name="email" component="small" />
          </label>
          <label className={invalidateField(formikBag.getFieldMeta('phone'))}>
            Váš telefon:
            <Field name="phone" type="tel" placeholder="+420..." />
            <ErrorMessage name="phone" component="small" />
          </label>
          <label className={invalidateField(formikBag.getFieldMeta('note'))}>
            Poznámka:
            <Field name="note" as="textarea" placeholder="Volejte pouze přes den..." rows={4} />
            <ErrorMessage name="note" component="small" />
          </label>
          <button type="submit" disabled={formikBag.isSubmitting}>
            {formikBag.isSubmitting ? loader : 'Kontaktujte mě'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
