import React from 'react';

interface TitleProps {
  title: string;
}

const Title: React.FC<TitleProps> = props => {
  return (
    <header>
      <div className="article__header">
        <h2>{props.title}</h2>
        {props.children}
      </div>
      <hr />
    </header>
  );
};

export default Title;
