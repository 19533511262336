import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

interface ProductProps {
  image: string;
  title: string;
  description: string;
}

const Product: React.FC<ProductProps> = props => {
  const [height, setHeight] = useState<string | number>(0);

  const updateHeight = () => setHeight(height === 0 ? 'auto' : 0);

  return (
    <div className="product__card">
      <div className="product__card--top">
        <div className="product__image">
          <picture>
            <source srcSet={`${process.env.PUBLIC_URL}${props.image}.webp`} type="image/webp" />
            <img src={`${process.env.PUBLIC_URL}${props.image}.jpg`} alt={props.title} />
          </picture>
        </div>
        <div className="product__description">
          <div>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
          </div>
          <button onClick={updateHeight}>Zobrazit {height ? 'méně' : 'více'}</button>
        </div>
      </div>
      <AnimateHeight duration={250} height={height}>
        {props.children}
      </AnimateHeight>
    </div>
  );
};

export default Product;
